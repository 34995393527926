
<template>
  <div class="h-min-content w-3/4 mx-auto">
    <div class="p-8 bg-white rounded-2xl shadow-lg mx-4">
      <h2 class="text-center text-2xl text-blue-dark py-2"><b>Su inconformidad fue registrada</b></h2>
      <p class="text-center text-sm text-gray-700">El código asignado para hacerle seguimiento es:</p>
      <h2 class="text-center text-4xl text-blue-dark py-6"><b>{{complaintCode}}</b></h2>
      <BaseButton type="button" @click.native="backToStart()" class="mx-auto w-1/2" label="VOLVER" />
    </div>
  </div>
</template>

<script>

export default {
  props: ['complaintCode','rated'],
  methods: {
    backToStart() {
        this.$router.push('/dashboard/complaints/');
    }
  }
}
</script>

<style>

</style>